<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button" v-show="!isReadonly">
        <el-button
          @click="open_save"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <div class="body">
      <!-- <BasicForm :isReadonly="isReadonly" :basicData="coronaryInfo" ref="BasicForm" /> -->

      <!-- 相关静脉信息 -->
      <div class="cirrhosis-vein-information">
        <cirrhosis-vein-information
          :allDetail="coronaryInfo"
          :formId="formData.formId"
          :isReadonly="isReadonly"
          :signed_status="isReadonly"
          ref="cirrhosisVeinInformation"
        ></cirrhosis-vein-information>
      </div>
      <!-- 球囊信息 -->
      <div class="balloon-stent-info">
        <balloon-information
          :allDetail="allDetail"
          :formId="formData.formId"
          :isReadonly="isReadonly"
          :signed_status="isReadonly"
          ref="balloonInformation"
        ></balloon-information>
        <!-- 支架信息 -->
        <stent-information
          :allDetail="allDetail"
          :formId="formData.formId"
          :isReadonly="isReadonly"
          :signed_status="isReadonly"
          ref="stentInformation"
        ></stent-information>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="coronaryInfo"
        complicationUrl="/v1/webconsole/comboData/get/CPH_complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/CPH_post_diagnosis"
        ref="ComplicationForm"
      />
      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="surgeryInfo?.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        ></surgery-procedure>
      </div>
      <!-- 手术结果 -->
      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadonly"
          :templateData="templateResultData"
          :surgeryInfo="surgeryInfo?.studyResult"
          :operation="operation"
          ref="result"
        ></surgery-result>
      </div> -->
      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="surgeryInfo"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 700px; width: 100%"></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'

import CirrhosisVeinInformation from './components/CirrhosisVeinInformation.vue'
import BalloonInformation from './components/BalloonInformation.vue'
import StentInformation from './components/StentInformation.vue'
import SignReport from '../components/SignReport.vue'

import { mapState } from 'vuex'

import BasicForm from './components/BasicForm.vue'
import ComplicationForm from '../components/ComplicationForm.vue'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    CirrhosisVeinInformation,
    BalloonInformation,
    StentInformation,
    SignReport,
    CqtSelect,
    CqtInput,
    CqtRadio,
    BasicForm,
    ComplicationForm
  },
  data() {
    return {
      report_address: '',
      dialogVisible: false,
      reportInfo: null,
      signStatus: false,
      heClassificationExitActive: require('@/assets/img/nerve/heClassification.png'),
      heClassificationIsExitHover: false, //是否悬浮到图标上
      isReadonly: false,
      liverCirrhosis_status: true,
      hemorrhage_status: true,
      ascites_status: true,
      liverBrain_status: true,
      myelopathy_status: true,
      liverCancer_status: true,
      peritonitis_status: true,
      jaundice_status: true,
      death_status: true,
      supportFailure_status: true,
      liverTransplantation_status: true,
      loading: false,
      coronaryInfo: {},
      menuListData: null,
      allDetail: {},
      formData: {
        formId: '',
        id: '',
        items: []
      },
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      // 手术经过结果信息
      operationName: '肝硬化静脉门高压',
      surgeryInfo: null,
      operation: 'Cirrhosis',
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },
      selectedRow: null,
      tableKey: null,
      // cirrhosisInfo: {
      //   gastroscopy: null, //胃镜检查日期
      //   venousDegree: null, //食管-胃底静脉程度
      //   gastroscopeTips: null, //胃镜提示
      //   indication: null, //适应症
      //   liverCirrhosis: null, //是否合并肝硬化
      //   cirrhosisEtiology: null, //肝硬化病因
      //   venousImaging: null, //门静脉显象
      //   vascularApproach: null //血管入路
      // },
      cirrhosisPrognosisInfo: {
        hemorrhage: null, //有无出血
        bleedingTime: null, //出血时间
        ascites: null, //有无腹水
        ascitesTime: null, //腹水时间
        ascitesDegree: null, //腹水程度
        liverBrain: null, //有无肝脑
        heClassification: null, //HE分级
        liverBrainTime: null, //肝脑时间
        myelopathy: null, //有无肝性脊髓病
        myelopathyTime: null, //肝性脊髓病时间
        liverCancer: null, //是否发现肝癌
        liverCancerTime: null, //发现肝癌时间
        peritonitis: null, //有无自发性腹膜炎
        peritonitisTime: null, //自发性腹膜炎时间
        jaundice: null, //有无黄疸
        jaundiceTime: null, //黄疸时间
        death: null, //是否死亡
        deathTime: null, //死亡时间
        supportFailure: null, //是否支架失功
        supportFailureTime: null, //支架失功出血时间
        liverTransplantation: null, //是否肝移植
        liverTransplantationTime: null //肝移植时间
      },
      cirrhosisLiverFunctionInfo: {
        childPughScore: null, //Child-Pugh评分
        childPughGrade: null, //Child-Pugh等级
        liverAscitesGrading: null, //腹水分级
        liverHeClassification: null //HE分级
      },
      itemArr: [
        {
          // 球囊信息
          elementId: 'cirrhosisBalloonInformation',
          value: null,
          extendTable: null
        },
        {
          // 支架植入
          elementId: 'cirrhosisSupportInformation',
          value: null,
          extendTable: null
        }
      ]
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          // let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.cirrhosisLiverFunction.id}`
          // let formData = new FormData()
          // formData.append('elementName', 'cirrhosisLiverFunction')
          // this.$api.post(url, formData).then(
          //   ({ data: res }) => {
          //     if (res && res.data && res.data.length > 0) {
          //       this.cirrhosisLiverFunctionInfo = res.data[0]
          //     }
          //   },
          //   () => {}
          // )
        }
      }
    }
  },
  methods: {
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info?.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info?.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info?.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api.get(formUrl, data).then(({ data: res }) => {
            if (res && res.data) {
              // 如果没有数据则使用初始化
              if (res.data.length !== 0) {
                this.formList = res.data
                this.formData.id = res.data[0].id
                this.getTableData()
              }
              this.getReportInfo()
            }
          })

          // 查询基本数据
          this.$api
            .get(
              `/v1/webconsole/study/operation/universal/${this.info?.uuid}?moduleCode=${this.menuListData.code}`,
              data
            )
            .then(({ data: res }) => {
              if (res && res.data) {
                // 如果没有数据则使用初始化
                if (res.data) {
                  let obj = {}
                  for (let i in res.data) {
                    for (let j in this.universalStudy) {
                      if (j === i) {
                        obj[i] = res.data[i]
                      }
                    }
                  }
                  this.coronaryInfo = { ...this.coronaryInfo, ...obj }
                  this.surgeryInfo = obj
                }
              }
            })
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取表单数据
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          delete res.data.vascularApproach
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
          }
          this.coronaryInfo = { ...this.coronaryInfo, ...obj }

          this.allDetail = res.data
        }
      })
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then((response) => {
                    pdfLoading.close()
                    if (response.data && response.data.data) {
                      if (!response.data.data.pdfFileName) {
                        return this.$message.info('暂无报告')
                      }
                      var s = encodeURI(response.data.data.pdfFileName)
                      if (response.data.data.pdfFileName.includes('http')) {
                        this.report_address = s
                      } else {
                        this.report_address =
                          PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                      }
                      this.dialogVisible = true
                    } else {
                      return this.$message.info('暂无报告')
                    }
                  })
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },
    formatUtcTime(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      )
    },
    cirrhosisLiverFunction_delete() {
      this.cirrhosisLiverFunctionInfo = {
        childPughScore: null, //Child-Pugh评分
        childPughGrade: null, //Child-Pugh等级
        liverAscitesGrading: null, //腹水分级
        liverHeClassification: null //HE分级
      }
    },

    open_save() {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('是否保存TIPS信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = [] // 重置item
      let cirrhosisVeinTableInfo = this.$refs.cirrhosisVeinInformation.cirrhosisVeinTableInfo // 静脉信息
      let aorticInfo = { ...cirrhosisVeinTableInfo } // 全部基础信息

      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      this.loading = true
      let differentFields = {}

      for (let ele in this.universalStudy) {
        // 按要求分割对象
        for (let item in aorticInfo) {
          if (item === ele) {
            this.universalStudy[ele] = aorticInfo[ele]
          }
        }
      }
      for (let item in aorticInfo) {
        // 剩下没有相同字段的对象
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = aorticInfo[item]
        }
      }

      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }

      // 相关静脉信息
      // this.formData.items.push({
      //   elementId: 'cirrhosisVeinInformation',
      //   value: null,
      //   extendTable: this.$refs.cirrhosisVeinInformation.cirrhosisVeinTableData.map((element) =>
      //     this.convertElement(element)
      //   )
      // })
      this.handleData()
      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.currentPatient.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord, // 手术经过
        // studyResult: this.$refs.result.studyResult // 手术结果
      } // universalStudy字段
      let url = `/v1/webconsole/eform/universal/save/${this.currentPatient.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleData() {
      let balloonTableData = []
      let stentTableData = []
      // 球囊
      if (this.$refs.balloonInformation.balloonTableData.length !== 0) {
        balloonTableData = this.$refs.balloonInformation.balloonTableData.map((element) =>
          this.convertElement(element)
        )
      }
      // 支架
      if (this.$refs.stentInformation.stentTableData.length !== 0) {
        stentTableData = this.$refs.stentInformation.stentTableData.map((element) =>
          this.convertElement(element)
        )
      }

      this.itemArr.forEach((item) => {
        if (item.elementId === 'cirrhosisBalloonInformation') {
          item.extendTable = balloonTableData
        }
        if (item.elementId === 'cirrhosisSupportInformation') {
          item.extendTable = stentTableData
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      delete element.ids
      delete element.id
      const arr = Object.entries(element).map(([i, value]) => {
        if (value && typeof value !== 'string') {
          let subDetail
          subDetail = [
            Object.entries(value).map(([j, ele]) => ({
              elementId: j,
              value: ele,
              extendTable: null
            }))
          ]
          return {
            elementId:
              i === 'balloonTableData'
                ? 'cirrhosisBalloonInformation'
                : i === 'stentTableData'
                ? 'cirrhosisSupportInformation'
                : i,
            value: null,
            extendTable: subDetail
          }
        } else {
          return {
            elementId: i,
            value: value,
            extendTable: null
          }
        }
      })
      return arr
    },

    changeLiverCirrhosis(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisInfo.cirrhosisEtiology = ''
        this.liverCirrhosis_status = true
      } else {
        this.liverCirrhosis_status = false
      }
    },
    changeHemorrhage(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.bleedingTime = ''
        this.hemorrhage_status = true
      } else {
        this.hemorrhage_status = false
      }
    },
    changeAscites(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.ascitesTime = ''
        this.cirrhosisPrognosisInfo.ascitesDegree = ''
        this.ascites_status = true
      } else {
        this.ascites_status = false
      }
    },
    changeLiverBrain(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.heClassification = ''
        this.cirrhosisPrognosisInfo.liverBrainTime = ''
        this.liverBrain_status = true
      } else {
        this.liverBrain_status = false
      }
    },
    changeMyelopathy(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.myelopathyTime = ''
        this.myelopathy_status = true
      } else {
        this.myelopathy_status = false
      }
    },
    changeLiverCancer(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisPrognosisInfo.liverCancerTime = ''
        this.liverCancer_status = true
      } else {
        this.liverCancer_status = false
      }
    },
    changePeritonitis(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.peritonitisTime = ''
        this.peritonitis_status = true
      } else {
        this.peritonitis_status = false
      }
    },
    changeJaundice(val) {
      if (this.isReadonly) return
      if (val === '无') {
        this.cirrhosisPrognosisInfo.jaundiceTime = ''
        this.jaundice_status = true
      } else {
        this.jaundice_status = false
      }
    },
    changeDeath(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisPrognosisInfo.deathTime = ''
        this.death_status = true
      } else {
        this.death_status = false
      }
    },
    changeSupportFailure(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisPrognosisInfo.supportFailureTime = ''
        this.supportFailure_status = true
      } else {
        this.supportFailure_status = false
      }
    },
    changeLiverTransplantation(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisPrognosisInfo.liverTransplantationTime = ''
        this.liverTransplantation_status = true
      } else {
        this.liverTransplantation_status = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-finished::before {
  content: url('../../../assets/img/finished.png');
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .valve-basic-information-label {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-style: solid;
      border-width: medium;
      border-color: #c0c4cc;
      .inner-title {
        position: relative;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .valve-basic-information {
      // border-style: solid;
      // border-width: medium;
      // border-color: #f2f6fc;
      .valve-basic-information-head-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      // .valve-basic-information-head {
      //   height: 50px;
      //   display: flex;
      //   width: 100%;
      //   align-items: center;
      //   .except-button {
      //     height: 3rem;
      //     line-height: 3rem;
      //     display: flex;
      //     .valve-basic-information-head-text {
      //       padding-left: 10px;
      //       padding-right: 10px;
      //       font-size: 14px;
      //     }
      //   }
      //   .save-button {
      //     margin-right: 0.5rem;
      //     padding-left: 10px;
      //   }
      // }
      .valve-basic-information-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 30%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
          .input_has_unit {
            margin: 0;
          }
          .el-date-editor.el-input {
            width: 200px;
          }
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }

    .pre-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
      margin-top: 30px;
      margin-bottom: 30px;

      .pre-expansions-head {
        button {
          margin-right: 0.5rem;
        }
        display: flex;
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
        }
      }
    }

    .post-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #e4e7ed;
      margin-top: 30px;
      margin-bottom: 30px;

      .post-expansions-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
    .valve-implants-number {
      border-style: solid;
      border-width: medium;
      border-color: #dcdfe6;
      margin-top: 30px;
      margin-bottom: 30px;

      .valve-implants-number-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }

    .balloon-stent-info {
      display: flex;
      width: 100%;
    }
  }
}
</style>
