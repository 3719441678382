<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="球囊信息" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>球囊类型</span>
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/CPH_balloon_type"
          :value.sync="balloonTableInfo.balloonType"
        />
      </div>
      <div class="item-row">
        <span>球囊长度</span>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          unit="mm"
          v-model="balloonTableInfo.balloonLength"
          size="mini"
        ></cqt-input>
      </div>
      <div class="item-row">
        <span>球囊直径</span>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          unit="mm"
          v-model="balloonTableInfo.balloonWidth"
          size="mini"
        ></cqt-input>
      </div>
      <div class="item-row">
        <span>球囊数量</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="balloonTableInfo.balloonNumber"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
// import CmSelect from "../../../../components/CmSelect.vue";
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtInput from '../../../../components/cqt/CqtInput.vue'

export default {
  name: 'BalloonDialog',
  components: {
    CmDialogBorder,
    CqtSelect,
    CqtInput
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      balloonTableInfo: {
        balloonType: null, //球囊类型
        balloonSize: null, //球囊尺寸
        balloonNumber: null, //球囊数量
        balloonLength: null, //"球囊长度mm"
        balloonWidth: null //球囊直径
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    Show(bloodVessel) {
      this.balloonTableInfo = {}
      if (bloodVessel) this.balloonTableInfo = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      if (!this.balloonTableInfo.balloonType) {
        return this.$message.warning('球囊类型不能为空！')
      }
      this.$emit('save', this.balloonTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
