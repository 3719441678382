<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="支架信息" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>支架类型</span>
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/CPH_support_type"
          :value.sync="supportTableInfo.supportType"
        />
      </div>
      <div class="item-row">
        <span>支架长度</span>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="supportTableInfo.supportLength"
          size="mini"
          unit="mm"
        ></cqt-input>
      </div>
      <div class="item-row">
        <span>支架直径</span>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          size="mini"
          unit="mm"
          :min="0"
          v-model="supportTableInfo.supportDiameter"
        />
      </div>
      <div class="item-row">
        <span>支架数量</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="supportTableInfo.supportNumber"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
// import CmSelect from "../../../../components/CmSelect.vue";
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtInput from '../../../../components/cqt/CqtInput.vue'

export default {
  name: 'SupportDialog',
  components: {
    CmDialogBorder,
    CqtSelect,
    CqtInput
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      supportTableInfo: {
        cirrhosisId: null, //肝硬化门静脉高压主表主键
        supportType: null, //支架类型
        supportLength: null, //支架长度
        supportDiameter: '8', //支架直径
        supportNumber: null //支架数量
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    Show(bloodVessel) {
      this.supportTableInfo = {
        cirrhosisId: null, //肝硬化门静脉高压主表主键
        supportType: null, //支架类型
        supportLength: null, //支架长度
        supportDiameter: '8', //支架直径
        supportNumber: null //支架数量
      }
      if (bloodVessel) this.supportTableInfo = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      if (!this.supportTableInfo.supportType) {
        return this.$message.warning('支架类型不能为空！')
      }
      this.$emit('save', this.supportTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
