<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">相关静脉信息</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div class="muticards">
        <div class="input-form">
          <div class="input-form-item">
            <div class="input-form-item-label">TIPS穿刺套装</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_puncture_suit"
              :value.sync="cirrhosisVeinTableInfo.punctureSuit"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">上穿刺部位</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_upper_puncture_site"
              :value.sync="cirrhosisVeinTableInfo.upperPunctureSite"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">下穿刺部位</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_lower_puncture_site"
              :value.sync="cirrhosisVeinTableInfo.lowerPunctureSite"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">测压部位</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_manometry"
              :value.sync="cirrhosisVeinTableInfo.manometry"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">分流前压力</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="mmHg"
              v-model="cirrhosisVeinTableInfo.beforeDiversionPressure"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">分流后压力</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="mmHg"
              v-model="cirrhosisVeinTableInfo.afterDiversionPressure"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">门静脉压力</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="mmHg"
              v-model="cirrhosisVeinTableInfo.portalVein"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">下腔静脉压力</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="mmHg"
              v-model="cirrhosisVeinTableInfo.inferiorVenaCava"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">门静脉压力梯度（PPG）</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              :disabled="true"
              type="text"
              size="mini"
              unit="mmHg"
              v-model="cirrhosisVeinTableInfo.ppg"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">有无胃肾分流</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/Have_Not"
              :value.sync="cirrhosisVeinTableInfo.gastricRenalShunt"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">有无脾肾分流</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/Have_Not"
              :value.sync="cirrhosisVeinTableInfo.splenorenalShunt"
            />
          </div>
          <div class="input-form-item"></div>

          <div class="input-form-item">
            <div class="input-form-item-label">测试血管栓塞</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/Yes_No"
              :value.sync="cirrhosisVeinTableInfo.testVascularEmbolism"
            />
          </div>
          <div
            class="input-form-item"
            v-show="cirrhosisVeinTableInfo.testVascularEmbolism === '是'"
          >
            <div class="input-form-item-label">测试栓塞材料</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              type="text"
              size="mini"
              v-model="cirrhosisVeinTableInfo.testEmbolicMaterials"
            />
          </div>
          <div
            class="input-form-item"
            v-show="cirrhosisVeinTableInfo.testVascularEmbolism === '是'"
          >
            <div class="input-form-item-label">测试效果</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              type="text"
              size="mini"
              v-model="cirrhosisVeinTableInfo.testEffect"
            />
          </div>
          <div class="input-form-item">
            <div class="input-form-item-label">血管栓塞</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_vascular_embolism"
              :value.sync="cirrhosisVeinTableInfo.vascularEmbolism"
            />
          </div>

          <div
            v-if="
              cirrhosisVeinTableInfo.vascularEmbolism &&
              cirrhosisVeinTableInfo.vascularEmbolism !== '无栓塞'
            "
            class="input-form-item"
          >
            <div class="input-form-item-label">栓塞材料</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_embolic_materials"
              :value.sync="cirrhosisVeinTableInfo.embolicMaterials"
            />
          </div>
          <div v-if="shouldShowGlueType('胶水')" class="input-form-item">
            <div class="input-form-item-label">胶水类型</div>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/CPH_glue_type"
              :value.sync="cirrhosisVeinTableInfo.glueType"
            />
          </div>
          <div v-if="shouldShowGlueType('胶水')" class="input-form-item">
            <div class="input-form-item-label">胶水用量</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="ml"
              v-model="cirrhosisVeinTableInfo.glueDosage"
            />
          </div>
          <div v-if="shouldShowGlueType('弹簧圈')" class="input-form-item">
            <div class="input-form-item-label">弹簧圈数量</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="个"
              v-model="cirrhosisVeinTableInfo.coilCount"
            />
          </div>
          <div v-if="shouldShowGlueType('血管栓')" class="input-form-item">
            <div class="input-form-item-label">血管栓数量</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              size="mini"
              unit="个"
              v-model="cirrhosisVeinTableInfo.vascularThrombosisCount"
            />
          </div>
        </div>
      </div>
      <cirrhosis-vein-dialog
        ref="cirrhosisVeinDialog"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></cirrhosis-vein-dialog>
    </div>
  </div>
</template>

<script>
import CirrhosisVeinDialog from './CirrhosisVeinDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'CirrhosisVeinInformation',
  components: {
    CirrhosisVeinDialog,
    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    allDetail: {
      type: Object,
      default: () => ({})
    },
    formId: {
      type: String,
      require: true
    }
  },
  computed: {
    shouldShowGlueType() {
      return (type) => {
        return (
          this.cirrhosisVeinTableInfo.vascularEmbolism !== '无栓塞' &&
          this.cirrhosisVeinTableInfo.embolicMaterials &&
          this.cirrhosisVeinTableInfo.embolicMaterials.includes(type)
        )
      }
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        // this.cirrhosisVeinTableInfo = { ...this.cirrhosisVeinTableInfo, ...val }
        if (val) {
          for (let item in val) {
            if (this.cirrhosisVeinTableInfo.hasOwnProperty(item)) {
              this.cirrhosisVeinTableInfo[item] = val[item]
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
    selectedInfo: {
      handler(val) {
        if (val) {
          // this.$bus.$emit('toBalloon', val)
        }
      },
      immediate: true
    },
    // 时间
    'cirrhosisVeinTableInfo.portalVein': {
      handler(val) {
        if (!this.cirrhosisVeinTableInfo.inferiorVenaCava || !val) return
        const number = parseInt(val) - parseInt(this.cirrhosisVeinTableInfo.inferiorVenaCava)
        if (number < 0) {
          return (this.cirrhosisVeinTableInfo.ppg = 0)
        } else {
          this.cirrhosisVeinTableInfo.ppg = number
        }
      }
    },
    'cirrhosisVeinTableInfo.inferiorVenaCava': {
      handler(val) {
        if (!this.cirrhosisVeinTableInfo.portalVein || !val) return
        const number = parseInt(this.cirrhosisVeinTableInfo.portalVein) - parseInt(val)
        if (number < 0) {
          return (this.cirrhosisVeinTableInfo.ppg = 0)
        } else {
          this.cirrhosisVeinTableInfo.ppg = number
        }
      }
    },
    'cirrhosisVeinTableInfo.testVascularEmbolism': {
      handler(val) {
        if (val !== '是') {
          this.cirrhosisVeinTableInfo.testEmbolicMaterials = ''
          this.cirrhosisVeinTableInfo.testEffect = ''
        }
      }
    }
  },
  data() {
    return {
      cirrhosisVeinTableData: [],
      selectedInfo: {},
      tableKey: null,
      cirrhosisVeinTableInfo: {
        puncturePressure: null, //穿刺前压力
        punctureSuit: null, //TIPS穿刺套装
        punctureSite: null, //穿刺部位
        manometry: null, //测压
        hvpg: null, //肝静脉压力梯度(HVPG)
        portalVein: null, //门静脉压力
        inferiorVenaCava: null, //下腔静脉压力
        ppg: null, //门静脉压力梯度（PPG）
        shunt: null, //是否建立分流道
        shuntMode: null, //分流方式
        upperPunctureSite: null, //上穿刺部位
        lowerPunctureSite: null, //下穿刺部位
        beforeDiversionPressure: null, //分流前压力
        afterDiversionPressure: null, //分流后压力

        vascularEmbolism: null, //血管栓塞
        testVascularEmbolism: null, //测试血管栓塞
        testEmbolicMaterials: null, //测试栓塞材料
        testEffect: null, //测试效果
        gastricRenalShunt: null, //有无胃肾分流
        splenorenalShunt: null, //有无脾肾分流
        embolicMaterials: null, //栓塞材料

        glueType: null, //胶水类型
        glueDosage: null, //胶水用量
        coilType: null, //弹簧圈类型
        coilCount: null, //弹簧圈数量
        vascularThrombosisCount: null //血管栓数量
      }
    }
  },
  created() {},
  methods: {
    _getTableData() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail?.cirrhosisVeinInformation?.id}`
      let formData = new FormData()
      formData.append('elementName', 'cirrhosisVeinInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            this.cirrhosisVeinTableData = res.data

            for (let i = 0; i < this.cirrhosisVeinTableData.length; i++) {
              const element = this.cirrhosisVeinTableData[i]
              // 球囊
              let balloonUrl = `/v1/webconsole/eform/extendfields/${this.formId}/${element.id}`
              let balloonFormData = new FormData()
              balloonFormData.append('elementName', 'cirrhosisBalloonInformation')
              this.$api.post(balloonUrl, balloonFormData).then(
                (res) => {
                  // console.log('balloonTableData', res)
                  if (res.data && res.data.data) {
                    element.balloonTableData = res.data.data
                  } else {
                    element.balloonTableData = []
                  }
                },
                (err) => console.log(err)
              )
              // 支架
              let supportUrl = `/v1/webconsole/eform/extendfields/${this.formId}/${element.id}`
              let supportFormData = new FormData()
              supportFormData.append('elementName', 'cirrhosisSupportInformation')
              this.$api.post(supportUrl, supportFormData).then(
                (res) => {
                  // console.log('stentTableData', res)
                  if (res.data && res.data.data) {
                    element.stentTableData = res.data.data
                  } else {
                    element.stentTableData = []
                  }
                },
                (err) => console.log(err)
              )
            }
          }
        },
        () => {}
      )
    },

    cirrhosisVeinInsert() {
      this.$refs.cirrhosisVeinDialog.Show()
    },
    cirrhosisVeinModify() {
      if (!this.selectedInfo.id) return this.$message.warning('请先选择一条静脉信息')
      if (this.selectedInfo) {
        this.$refs.cirrhosisVeinDialog.Show(this.selectedInfo)
      }
    },
    cirrhosisVeinDelete() {
      //TODO
      if (!this.selectedInfo.id) return this.$message.warning('请先选择一条静脉信息')
      this.$confirm('是否删除该静脉信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.id) {
          for (let i = 0; i < this.cirrhosisVeinTableData.length; i++) {
            const element = this.cirrhosisVeinTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.cirrhosisVeinTableData.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.cirrhosisVeinTableData.length; i++) {
            const element = this.cirrhosisVeinTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.cirrhosisVeinTableData.splice(i, 1)
              break
            }
          }
        }
      }
    },
    _rowClick(row) {
      this.selectedInfo = {}
      this.selectedInfo = row
    },
    _doubleModify(row) {
      this.selectedInfo = {}
      this.selectedInfo = row
      this.$refs.cirrhosisVeinDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.cirrhosisVeinTableData.length; i++) {
          const element = this.cirrhosisVeinTableData[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.cirrhosisVeinTableData[i] = val
            this.$set(this.cirrhosisVeinTableData, i, this.cirrhosisVeinTableData[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.cirrhosisVeinTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.cirrhosisVeinTableData.length; i++) {
          const element = this.cirrhosisVeinTableData[i]
          if (val.id === element.id) {
            this.cirrhosisVeinTableData[i] = val
            this.$set(this.cirrhosisVeinTableData, i, this.cirrhosisVeinTableData[i])
          }
        }
      }
      this.selectedInfo = {}
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.muticards {
  .el-card {
    margin: 10px 10px 10px 0;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input_has_xldl {
      /deep/.el-input__inner {
        border: none;
      }
    }

    .input_has_unit {
      margin: 0;
    }

    .input-form-item {
      margin: 0.4rem 0.5rem;
      display: flex;
      align-items: center;

      .input-form-item-label {
        text-align: center;
        width: 120px;
      }
    }
  }

  .input-form-card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .input-form-item {
    min-width: 30%;
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 120px;
  }

  .card-complication {
    .is-bordered {
      margin: 5px 10px 5px 0;
    }
  }
}
.surgery-information {
  margin-top: 30px;
  margin-bottom: 20px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  .input_has_unit {
    width: 200px;
    margin: 0;
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
</style>
