<template>
  <div class="info-table">
    <span class="inner-title">支架信息</span>
    <div style="display: flex; padding: 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="stentInsert"
          :disabled="signed_status"
        >
          添加
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="stentDelete"
          :disabled="signed_status"
        >
          删除
        </el-button>
      </div>
    </div>
    <div style="margin: 10px 10px 0 10px">
      <el-table
        class="inner-table"
        highlight-current-row
        :data="stentTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        :key="tableKey"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="supportType" label="支架类型"></el-table-column>
        <el-table-column prop="supportLength" label="支架长度"></el-table-column>
        <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
        <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
      </el-table>
      <support-dialog ref="supportDialog" @save="_dialogSave"></support-dialog>
    </div>
  </div>
</template>

<script>
import SupportDialog from './SupportDialog.vue'
export default {
  name: 'StentInformation',
  components: {
    SupportDialog
  },
  props: {
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    allDetail: {
      type: Object,
      default: () => ({})
    },
    formId: {
      type: String,
      require: true
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        if (val) {
          this._getTableData()
        }
      }
    }
  },
  data() {
    return {
      stentTableData: [],
      selectedInfo: null,
      tableKey: null
    }
  },
  created() {},
  // beforeCreate() {
  //   this.$bus.$on('toBalloon', (msg) => {
  //     this.stentTableData = msg.stentTableData
  //   })
  // },
  methods: {
    _getTableData() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.cirrhosisSupportInformation?.id}`
      let formData = new FormData()
      formData.append('elementName', 'cirrhosisSupportInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            this.stentTableData = res.data
          }
        },
        () => {}
      )
    },
    stentInsert() {
      this.$refs.supportDialog.Show()
    },
    stentDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条支架信息')
      this.$confirm('是否删除该支架信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.id) {
          for (let i = 0; i < this.stentTableData.length; i++) {
            const element = this.stentTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.stentTableData.splice(i, 1)
              break
            }
          }
        } else {
          return this.$message.warning('当前支架信息不存在')
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.selectedInfo = null
      }
    },
    _rowClick(row) {
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.supportDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      if (!val.id) {
        this.stentTableData.push({
          ...val,
          id: Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000)
        })
      } else {
        for (let i = 0; i < this.stentTableData.length; i++) {
          const element = this.stentTableData[i]
          if (val.id === element.id) {
            this.stentTableData[i] = val
            this.$set(this.stentTableData, i, this.stentTableData[i])
          }
        }
      }
      this.selectedInfo = null
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  padding: 5px;
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
</style>
