<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.input_has_unit {
  width: 180px;
}
.dialogContent::-webkit-scrollbar {
  width: 12px;
}

.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.dialogContent {
  flex: 1;
  overflow: auto;
}

.info-table {
  border-left: solid #c0c4cc;
  border-top: solid #c0c4cc;
  border-bottom: solid #c0c4cc;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: relative;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="相关静脉信息" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <span>TIPS穿刺套装</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/CPH_puncture_suit"
            :value.sync="cirrhosisVeinTableInfo.punctureSuit"
          />
        </div>
        <div class="item-row">
          <span>上穿刺部位</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/CPH_upper_puncture_site"
            :value.sync="cirrhosisVeinTableInfo.upperPunctureSite"
          />
        </div>

        <div class="item-row">
          <span>分流前压力</span>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="mmHg"
            v-model="cirrhosisVeinTableInfo.beforeDiversionPressure"
          />
        </div>
        <div class="item-row">
          <span>门静脉压力</span>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="mmHg"
            v-model="cirrhosisVeinTableInfo.portalVein"
          />
        </div>
        <div class="item-row"></div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <span>测压部位</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/CPH_manometry"
            :value.sync="cirrhosisVeinTableInfo.manometry"
          />
        </div>
        <div class="item-row">
          <span>下穿刺部位</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/CPH_lower_puncture_site"
            :value.sync="cirrhosisVeinTableInfo.lowerPunctureSite"
          />
        </div>
        <div class="item-row">
          <span>分流后压力</span>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="mmHg"
            v-model="cirrhosisVeinTableInfo.afterDiversionPressure"
          />
        </div>

        <div class="item-row">
          <span>下腔静脉压力</span>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="mmHg"
            v-model="cirrhosisVeinTableInfo.inferiorVenaCava"
          />
        </div>
        <div class="item-row">
          <span>门静脉压力梯度（PPG）</span>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="mmHg"
            v-model="cirrhosisVeinTableInfo.ppg"
          />
        </div>
      </div>
      <div class="top-item-row">
        <!-- <div class="item-row">
          <span>是否建立分流道</span>
          <cqt-radio
            :value.sync="cirrhosisVeinTableInfo.shunt"
            :isReadonly="isReadonly"
            style="margin-left: 15px; padding: 5px 0 0"
          >
            <el-radio label="是" @change="changeShunt">是</el-radio>
            <el-radio label="否" @change="changeShunt">否</el-radio>
          </cqt-radio>
        </div> -->
        <div class="item-row">
          <span>分流方式</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/CPH_shunt_mode"
            :value.sync="cirrhosisVeinTableInfo.shuntMode"
            :disabled="shunt_status"
          />
        </div>
        <div class="item-row"></div>
        <div class="item-row"></div>
        <div class="item-row"></div>
      </div>
      <!-- 球囊扩张 -->
      <div v-if="cirrhosisVeinTableInfo.shuntMode === '球囊扩张'" class="info-table">
        <span class="inner-title">球囊信息</span>
        <div style="display: flex; padding: 10px">
          <div v-show="!isReadonly">
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              :disabled="isReadonly"
              @click="balloonInsert"
            >
              添加
            </el-button>
            <el-button
              size="mini"
              type="danger"
              class="commonBtn"
              :disabled="isReadonly"
              @click="balloonDelete"
            >
              删除
            </el-button>
          </div>
        </div>
        <div style="margin: 10px 10px 0 10px">
          <el-table
            class="inner-table"
            highlight-current-row
            :data="balloonTableData"
            :header-cell-style="{ padding: 0 }"
            height="200px"
            style="width: 99%"
            @row-click="_rowClick"
            :key="tableKey"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="balloonType" label="球囊类型"></el-table-column>
            <el-table-column prop="balloonSize" label="球囊尺寸"></el-table-column>
            <el-table-column prop="balloonNumber" label="球囊数量"></el-table-column>
          </el-table>
          <balloon-dialog ref="balloonDialog" @save="_balloonDialogSave"></balloon-dialog>
        </div>
      </div>
      <!-- 支架植入 -->
      <div class="info-table" v-if="cirrhosisVeinTableInfo.shuntMode === '支架植入'">
        <span class="inner-title">支架信息</span>
        <div style="display: flex; padding: 10px">
          <div v-show="!isReadonly">
            <el-button size="mini" :disabled="isReadonly" type="primary" @click="stentInsert">
              添加
            </el-button>
            <el-button size="mini" :disabled="isReadonly" type="danger" @click="stentDelete">
              删除
            </el-button>
          </div>
        </div>
        <div style="margin: 10px 10px 0 10px">
          <el-table
            class="inner-table"
            highlight-current-row
            :data="stentTableData"
            :header-cell-style="{ padding: 0 }"
            height="200px"
            style="width: 99%"
            @row-click="_rowClick"
            :key="tableKey"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="supportType" label="支架类型"></el-table-column>
            <el-table-column prop="supportLength" label="支架长度"></el-table-column>
            <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
            <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
          </el-table>
          <support-dialog ref="supportDialog" @save="_supportDialogSave"></support-dialog>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

import BalloonDialog from './BalloonDialog.vue'
import SupportDialog from './SupportDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'CirrhosisVeinDialog',
  components: {
    CmDialogBorder,
    BalloonDialog,
    SupportDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      shunt_status: true,
      cirrhosisVeinTableInfo: {
        puncturePressure: null, //穿刺前压力
        punctureSuit: null, //TIPS穿刺套装
        punctureSite: null, //穿刺部位
        manometry: null, //测压
        hvpg: null, //肝静脉压力梯度(HVPG)
        portalVein: null, //门静脉压力
        inferiorVenaCava: null, //下腔静脉压力
        ppg: null, //门静脉压力梯度（PPG）
        shunt: null, //是否建立分流道
        shuntMode: null, //分流方式
        upperPunctureSite: null, //上穿刺部位
        lowerPunctureSite: null, //下穿刺部位
        beforeDiversionPressure: null, //分流前压力
        afterDiversionPressure: null //分流后压力
      },
      tableKey: null,
      balloonTableData: [],
      stentTableData: [],
      operationId: null,
      tableData: [] // 所有耗材列表
    }
  },
  watch: {
    cirrhosisVeinTableInfo: {
      handler(val) {
        if (val) {
          if (val.balloonTableData) {
            this.balloonTableData = val.balloonTableData
          }
          if (val.stentTableData) {
            this.stentTableData = val.stentTableData
          }
        }
      }
    }
  },
  created() {},
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  methods: {
    _rowClick(row) {
      this.selectedInfo = {}
      this.selectedInfo = row
    },
    Show(bloodVessel) {
      this.cirrhosisVeinTableInfo = {}
      if (bloodVessel) this.cirrhosisVeinTableInfo = deepClone(bloodVessel)

      if (this.cirrhosisVeinTableInfo.shunt === '是') {
        this.shunt_status = false
      }
      this.isShow = true
    },
    Save() {
      if (this.shunt_status || !this.cirrhosisVeinTableInfo.shuntMode) {
        this.cirrhosisVeinTableInfo.balloonTableData = []
        this.cirrhosisVeinTableInfo.stentTableData = []
      } else if (this.cirrhosisVeinTableInfo.shuntMode === '球囊扩张') {
        this.cirrhosisVeinTableInfo.stentTableData = []
      } else if (this.cirrhosisVeinTableInfo.shuntMode === '支架植入') {
        this.cirrhosisVeinTableInfo.balloonTableData = []
      }
      this.$emit('save', this.cirrhosisVeinTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
    },
    changeShunt(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.cirrhosisVeinTableInfo.shuntMode = ''
        this.shunt_status = true
      } else {
        this.shunt_status = false
      }
    },
    // 球囊添加
    balloonInsert() {
      this.$refs.balloonDialog.Show()
    },
    // 球囊新增
    _balloonDialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.balloonTableData.length; i++) {
          const element = this.balloonTableData[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.balloonTableData[i] = val
            this.$set(this.balloonTableData, i, this.balloonTableData[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.balloonTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.balloonTableData.length; i++) {
          const element = this.balloonTableData[i]
          if (val.id === element.id) {
            this.balloonTableData[i] = val
            this.$set(this.balloonTableData, i, this.balloonTableData[i])
          }
        }
      }
      this.selectedInfo = {}
      this.cirrhosisVeinTableInfo.balloonTableData = this.balloonTableData
    },
    balloonDelete() {
      if (!this.selectedInfo?.balloonType) return this.$message.warning('请先选择一条球囊信息')
      this.$confirm('是否删除该球囊信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._balloonDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 球囊删除
    _balloonDelete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.id) {
          for (let i = 0; i < this.balloonTableData.length; i++) {
            const element = this.balloonTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.balloonTableData.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.balloonTableData.length; i++) {
            const element = this.balloonTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.balloonTableData.splice(i, 1)
              break
            }
          }
        }
        this.cirrhosisVeinTableInfo.balloonTableData = this.balloonTableData
      }
    },
    // 支架添加
    stentInsert() {
      this.$refs.supportDialog.Show()
    },
    _supportDialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.stentTableData.length; i++) {
          const element = this.stentTableData[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.stentTableData[i] = val
            this.$set(this.stentTableData, i, this.stentTableData[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.stentTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.stentTableData.length; i++) {
          const element = this.stentTableData[i]
          if (val.id === element.id) {
            this.stentTableData[i] = val
            this.$set(this.stentTableData, i, this.stentTableData[i])
          }
        }
      }
      this.selectedInfo = {}
      this.cirrhosisVeinTableInfo.stentTableData = this.stentTableData
    },
    // 支架删除
    stentDelete() {
      if (!this.selectedInfo?.supportType) return this.$message.warning('请先选择一条支架信息')
      this.$confirm('是否删除该支架信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._stentDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _stentDelete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.id) {
          for (let i = 0; i < this.stentTableData.length; i++) {
            const element = this.stentTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.stentTableData.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.stentTableData.length; i++) {
            const element = this.stentTableData[i]
            if (this.selectedInfo.id === element.id) {
              this.stentTableData.splice(i, 1)
              break
            }
          }
        }
        this.cirrhosisVeinTableInfo.stentTableData = this.stentTableData
      }
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
